<template>
  <a id="tg-btnbacktotop" class="tg-btnbacktotop" @click="backToTop" href="javascript:;">
    <i class="icon-chevron-up"></i>
  </a>
</template>

<script>
export default {
  name: 'BackToTop',
  methods: {
    backToTop() {
      let _scrollUp = $('html, body');
      _scrollUp.animate({ scrollTop: 0 }, 'slow');
    }
  }
}
</script>