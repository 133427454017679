<template>
  <footer id="tg-footer" class="tg-footer tg-haslayout">
    <div style="display:flex;flex-direction: column; align-items:center;margin-top:32px;">
      <div style="font-size:20px;">网站法律声明</div>
      <div style="margin-top:9px;color:rgba(0, 0, 0, 0.5)"
        >本网站所登载的文章仅供学习、交流之用，版权属于原作者，本站登载并不表示我们同意其观点。如因作品内容、版权或其它问题认为本站不能登载，请及时与本站联系。</div
      >
      <div
        style="margin-top:11px; width:1042px;height:10;border-bottom:1px solid rgba(0, 0, 0, 0.3);"
      >
      </div>
      <div style="margin-top:8px; display: flex; justify-content: space-between; width: 380px;">
        <span>联系我们</span>
        <span>|</span>
        <span>合作加盟</span>
        <span>|</span>
        <span>在线服务</span>
        <span>|</span>
        <span>关于我们</span>
      </div>

      <div style="color:rgba(0, 0, 0, 0.5)">
        吉林音像出版社制作中心版权所有吉ICP备14001552号-1
      </div>
      <div style="color:rgba(0, 0, 0, 0.5)">
        联系电话:0431-81629660&nbsp;&nbsp;地址:长春市人民大街4646号&nbsp;&nbsp;传真:0431-86012893
      </div>
    </div>
    <div class="tg-footerbar" style="margin-top:20px;">
      <back-to-top />
    </div>
  </footer>
</template>

<script>
import BackToTop from '../components/BackToTop'
export default {
  name: 'Footer',
  components: {
    BackToTop,
  },
}
</script>
