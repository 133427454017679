import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const routes = [
  {
    name: 'home',
    meta: {
      title: '首页',
      nav: true,
    },
    path: '/',
    component: () => import('../pages/index'),
  },
  {
    name: 'musicbook',
    meta: {
      title: '音乐书城',
      nav: true,
    },
    props: {
      topType: 'music',
    },
    path: '/musicbook',
    component: () => import('../pages/books'),
  },
  {
    name: 'childbooks',
    meta: {
      title: '少儿书城',
      nav: true,
    },
    props: {
      topType: 'child',
    },
    path: '/childbooks',
    component: () => import('../pages/books'),
  },
  {
    name: 'ebooks',
    meta: {
      title: '电子书城',
      nav: true,
    },
    props: {
      topType: 'ebook',
    },
    path: '/ebooks',
    component: () => import('../pages/books'),
  },
  {
    name: 'bookDetail',
    meta: {
      title: '图书详情',
    },
    path: '/bookdetail',
    component: () => import('../pages/bookdetail'),
  },
  {
    name: 'media',
    meta: {
      title: '视听馆',
      nav: true,
    },
    path: '/media',
    component: () => import('../pages/video'),
  },
  {
    name: 'game',
    meta: {
      title: '游戏馆',
      nav: true,
    },
    path: '/game',
    component: () => import('../pages/newsdetail'),
  },
  {
    name: 'videoDetail',
    meta: {
      title: '视频详情',
    },
    path: '/videodetail',
    component: () => import('../pages/videodetail'),
  },
  {
    name: 'articleDetail',
    meta: {
      title: '新闻详情',
    },
    path: '/articledetail',
    component: () => import('../pages/newsdetail'),
  },
  {
    name: 'rights',
    meta: {
      title: '版权保护',
      nav: true,
    },
    path: '/rights',
    component: () => import('../pages/newsdetail'),
  },
  {
    name: 'about',
    meta: {
      title: '关于我们',
      nav: true,
    },
    path: '/about',
    component: () => import('../pages/about.vue'),
    children: [
      {
        name: 'about',
        path: 'about',
        meta: {
          title: '公司简介',
        },
        component: () => import('../pages/about/index.vue'),
      },
      {
        name: 'contact',
        path: 'contact',
        meta: {
          title: '联系方式',
        },
        component: () => import('../pages/about/contact'),
      },
      {
        name: 'business',
        path: 'business',
        meta: {
          title: '商务合作',
        },
        component: () => import('../pages/about/business'),
      },
    ],
  },

  {
    name: 'search',
    meta: {
      title: '搜索',
    },
    path: '/search/:defaultActive',
    component: () => import('../pages/searchBooks'),
  },
]

let router = new Router({
  routes,
  mode: 'hash',
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export { routes }

export default router
