<template>
  <header id="tg-header" class="tg-header tg-haslayout">
    <div class="tg-middlecontainer">
      <div class="container">
        <div class="row" style="display: flex; justify-content:space-between;align-items:flex-end">
          <div
            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
            style="display:flex; align-items:flex-end;font-size:16px;"
          >
            <strong class="tg-logo">
              <a href="/"
                ><img src="/images/logo.png" width="180" height="63" alt="company name here"
              /></a>
            </strong>
            <span style="margin-left:18px;font-size:16px;">吉林音像出版社旗下音乐门户网站</span>
          </div>
          <div
            class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
            style="display:flex;justify-content:flex-end;"
          >
            <div>
              <div class="tg-formtheme tg-formsearch">
                <fieldset>
                  <input
                    type="text"
                    name="search"
                    class="typeahead1 form-control1"
                    style="width:366px;border-radius:800px;"
                    placeholder="音乐图书"
                    v-model="keyword"
                  />
                  <button type="button" @click="search">
                    <i class="icon-magnifier"></i>
                  </button>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tg-navigationarea" style="display:flex;justify-content:center;">
      <div style="width: 1200px;">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <nav id="tg-nav" class="tg-nav">
              <div id="tg-navigation" class="collapse navbar-collapse tg-navigation">
                <ul style="display:flex;justify-content:space-between;">
                  <li
                    style="flex:1;"
                    v-for="(menu, index) in menus"
                    :key="index"
                    :class="$route.name == menu.key ? 'current-menu-item' : ''"
                  >
                    <router-link :to="menu.to" style="font-size:20px;">{{ menu.name }}</router-link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { routes } from '../router/index'
export default {
  name: 'Header',
  data() {
    let menus = routes
      .filter((route) => {
        return route.meta.nav
      })
      .map((menu) => {
        return {
          key: menu.name,
          name: menu.meta.title,
          to: {
            name: menu.name,
          },
        }
      })
    return {
      menus,
      keyword: '',
    }
  },
  methods: {
    search() {
      this.$router.push({
        name: 'search',
        query: {
          keyword: this.keyword,
        },
      })
    },
  },
}
</script>
