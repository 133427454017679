<template>
  <div id="app">
    <div class="tg-home tg-homeone">
      <div id="tg-wrapper" class="tg-wrapper tg-haslayout">
        <Header />
        <transition name="fade" mode="out-in">
          <!-- <transition> -->
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import Header from './layouts/header'
import Footer from './layouts/footer'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  async created() {
    console.log(process.env)
    let c = new cloud.Cloud({
      // 必填，表示是未登录模式
      identityless: true,
      // 资源方 AppID
      resourceAppid: process.env.VUE_APP_WX_APPID,
      // 资源方环境 ID
      resourceEnv: process.env.VUE_APP_WX_CLOUD_ENV,
    })
    await c.init()
    window.c = c
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
