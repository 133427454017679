import Vue from 'vue'
const EventBus = new Vue()

Vue.use({
  install: function (Vue) {
    Vue.mixin({
      data () {
        return {
          EventBus: EventBus
        }
      }
    })
  }
})
